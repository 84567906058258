import React from 'react';
import KkbHtml from './kkbhtml'


const Kkb = () => {
    return(
        <KkbHtml />
    )
}

export default Kkb