import React from 'react';
import { Container, Row, Col, Card } from 'react-bootstrap';
import TopDark from '../../Modules/Top/TopDarkRelative';
import ModelTable from './modelTable';
import KkbSlider from './kkbslider';
import CompairedImages from '../../Modules/CompairImgs/CompairKkb/compairimagetest';
// import ProjectCard from '../../Modules/ProjectsCards'
import KkbFormButton from '../../Modules/ContactForms/KkbForm';
import Footer from '../../Modules/FooterPage';





const KkbPage = () => {

    return(
        <>
            <Container fluid>
                <Row>
                    <Col>
                        <TopDark />
                    </Col>
                </Row>
            </Container>

            <div className="refeng-page-container">
                <div className="postcontent">
                    <h1>Компрессорно-конденсаторный агрегат</h1>
                    <h2>Компактные компрессорно-конденсаторные блоки (ККБ) для подключения к контуру оборотной воды производства ООО "Рефинжиниринг".</h2>

                    <p><strong>Компания "Рефинжиниринг" производит широкий модельный ряд компрессорно-конденсаторных агрегатов(блоков) с холодильной мощностью от 1,5 до 150кВт.</strong></p>
                    <KkbSlider />
                    <br/><br/>
                    <p><strong>Главные преимущества компрессорно-конденсаторных агрегатов нашего производства:</strong></p>
                    <ul class="post-ul-dot"  style={{padding: '0 0 35px 0'}}>
                        <li class="post-li-dot">Высокая энергоэффективность </li>
                        <li class="post-li-dot">Нет теплопритока в помещении в месте установки ККБ</li>
                        <li class="post-li-dot">Оттайка горячим газом. Энергопотребление ТЭНов оттайки = 0кВт</li>
                        <li class="post-li-dot">Во время оттайки тепло плавления льда возвращается в систему уменьшая нагрузку на чиллер с оборотной водой</li>
                        <li class="post-li-dot">Компактные размеры и малый вес</li>
                        <li class="post-li-dot">Малая заправка фреоном</li>
                        <li class="post-li-dot">Одна сторона обслуживания</li>
                        <li class="post-li-dot">Возможно исполнение в герметичном кожухе (удобно для предприятий по производству
                        хлебобулочных изделий)</li>
                    </ul>
                    <Container fluid>
                        <Row>
                            <Col></Col>
                            <Col xl={7}>
                                <CompairedImages />
                            </Col>
                            <Col></Col>
                        </Row>
                    </Container>

                    <p style={{padding: '35px 0 35px 0'}}>3D моделирование позволяет наглядно продемонстрировать агрегаты заказчику и сборщикам на производстве. 
                                Конструировать высокоэффективные компрессорно-конденсаторные блоки, которые удобно обслуживать.</p>
            
                    <Container fluid>
                        <Row>
                            <Col></Col>
                            <Col xl={8}>
                                <figure>
                                    <img className="kkb-animation-image" src="https://raw.githubusercontent.com/surreum/imagestore/main/refeng/blog/kkb/kkb_animation.gif" alt="Анимированная схема работы компрессорно-конденсаторного блока" />
                                    <figcaption><em>Анимированная схема работы компрессорно-конденсаторного блока</em></figcaption>
                                </figure>
                            </Col>
                            <Col></Col>
                        </Row>
                    </Container>
                    <br/><br/>
                    <ModelTable />
                    <br/>
                    <Container fluid>
                        <Row>
                            <Col></Col>
                            <Col xl={6}>
                                <div class="videoWrapper">
                                    <iframe width="660" height="415" src="https://www.youtube.com/embed/6le1n2lp9nU" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                                    <p></p>
                                    <figcaption><em>Видеообзор компрессорно-конденсаторного агрегата "Рефинжиниринг"</em></figcaption>
                                </div>
                            </Col>
                            <Col></Col>
                        </Row>
                    </Container>
                    <p>
                        В видеообзоре представлено два компрессорно-конденсаторных агрегата CCW-140, 
                        каждый блок имеет следующие характеристики при температуре оборотной воды +7/+12С:
                    </p>
                    <ul class="post-ul-checked">
                        <li class="post-li-checked">Q=36,5кВт, N=3,9кВт, COP=Q/N=9,4 (Режим для камеры овощей и фруктов +4..+6С)</li>
                        <li class="post-li-checked">Q=29,2кВт, N=4,3кВт, COP=Q/N=6,8 (Режим для камеры фрэш, молочки и гастрономии 0..+2С)</li>
                        <li class="post-li-checked">Q=19,6кВт, N=4,5кВт, COP=Q/N=4,3 (Режим для низкотемпературной камеры -18С).</li>
                    </ul>

                    <h3 style={{textAlign: 'center'}}>Компрессорно-конденсаторные агрегаты "Рефинжиниринг" работают на производствах:</h3>
                    <Container fluid style={{padding: '35px 0 45px 0'}}>
                        <Row>
                            <Col xl={4}>
                                <Card>
                                    <Card.Img variant="top" src="https://raw.githubusercontent.com/surreum/imagestore/main/refeng/cards_img/sapsan.jpg" alt="Карточка проекта Сапсан с применением компрессорно-конденсаторных блоков Рефинжиниринг" />
                                    <Card.Body>
                                        <Card.Title>ГК "Сапсан"</Card.Title>
                                        <Card.Text>
                                            Фабрика по производству кондитерских, кулинарных и хлебобулочных изделий.
                                        </Card.Text>
                                        <div style={{textAlign: 'center'}}>
                                            <a href="/realizovannye_proekty/foodcode/"><button class="card__button">ПОДРОБНЕЕ</button></a>
                                        </div>
                                    </Card.Body>
                                </Card>
                            </Col>
                            <Col xl={4}>
                                <Card>
                                    <Card.Img variant="top" src="https://raw.githubusercontent.com/surreum/imagestore/main/refeng/cards_img/card-kolomenskiy.jpg" alt="Карточка проекта Сапсан с применением компрессорно-конденсаторных блоков Рефинжиниринг" />
                                    <Card.Body>
                                        <Card.Title>ХЗ "Коломенский"</Card.Title>
                                        <Card.Text>
                                            Завод по производству кондитерских и хлебобулочных изделий.
                                        </Card.Text>
                                        <div style={{textAlign: 'center'}}>
                                            <a href="/realizovannye_proekty/kolomenskiy/"><button class="card__button">ПОДРОБНЕЕ</button></a>
                                        </div>
                                    </Card.Body>
                                </Card>
                            </Col>
                            <Col xl={4}>
                                <Card>
                                    <Card.Img variant="top" src="https://raw.githubusercontent.com/surreum/imagestore/main/refeng/cards_img/card-ecomilk-syr.jpg" alt="Карточка проекта Сапсан с применением компрессорно-конденсаторных блоков Рефинжиниринг" />
                                    <Card.Body>
                                        <Card.Title>МК "Озерецкий"</Card.Title>
                                        <Card.Text>
                                            Новый производственный цех по выпуску готовых сыров.
                                        </Card.Text>
                                        <div style={{textAlign: 'center'}}>
                                            <a href="/realizovannye_proekty/ecomilk-syr/"><button class="card__button">ПОДРОБНЕЕ</button></a>
                                        </div>
                                    </Card.Body>
                                </Card>
                            </Col>
                        </Row>
                    </Container>
                    
                    <p class="post-header">
                        <strong>
                            ООО "Рефинжиниринг" спроектирует холодильное оборудование для вашего производства с наилучшими характеристиками
                            и наибольшей энергоэффективностью, исходя из ваших бизнес-задач. <br/> Наши компрессорно-конденсаторные блоки
                            надежно зарекоммендовали себя на производствах наших заказчиков. 
                        </strong>
                    </p>
                    <Container fluid>
                        <Row>
                            <Col></Col>
                            <Col style={{display: 'grid', justifyContent: 'center'}}><KkbFormButton /></Col>
                            <Col></Col>
                        </Row>
                    </Container>
                    
                </div>
            </div>

            <Footer />
        </>
    )
}

export default KkbPage