import React from "react";
import ModalFormButton from "./ModalFormButton"


const KkbForm = () => (
<>
   <ModalFormButton />
</>
)

export default KkbForm