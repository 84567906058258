import React from "react"
import Seo from "../../components/seo"

import KkbComponent from "../../components/EquipmentPages/Kkb"


const Kkb = () => (

  <>
    <Seo 
      title="Купить компрессорно-конденсаторный блок или агрегат в Москве"
      description="Купить компрессорно-конденсаторные блоки или агрегаты Рефинжиниринг. Конструирование, производство и установка промышленных компрессорно-конденсаторных блоков (ККБ)."
      keywords="Компрессорно-конденсаторный блок, компрессорно-конденсаторный агрегат, купить ккб, ккб установка, оборотная вода"
    />
    <KkbComponent />
  </>
  
)

export default Kkb