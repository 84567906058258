import React from 'react'
import ModalWindow from './ModalWindow'


export default function OrderButton() {
    const [modalShow, setModalShow] = React.useState(false);
  
    return (
      <>
        <button className="ref-blue-button btn btn-ref btn-xl" onClick={() => setModalShow(true)}>
            ЗАПРОСИТЬ ККБ
        </button>
  
        <ModalWindow
          show={modalShow}
          onHide={() => setModalShow(false)}
        />
      </>
    );
  }