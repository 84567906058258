import React from "react"
import SliderPrevButton from './SliderPrevButton'


const PrevButton = () => (
<>
    <SliderPrevButton />
</>
)

export default PrevButton