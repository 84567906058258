import React, { useState } from "react";
import PropTypes from 'prop-types'; 
import { useStaticQuery, graphql } from "gatsby"
// import { GatsbyImage, getImage } from "gatsby-plugin-image"

import Lightbox from 'fslightbox-react'; 
import Slider from "react-slick";

import NextButton from "../../Elements/Buttons/SliderButtons/NextButton"
import PrevButton from "../../Elements/Buttons/SliderButtons/PrevButton"


const  KkbSlider = () => {

    const [lightboxController, setLightboxController] = useState({
        toggler: false,
        slide: 0
        });

    const data = useStaticQuery(graphql`
    {
      allWpPage(filter: {slug: {eq: "artkkb"}}) {
        nodes {
          postgallery {
            photos {
              localFile {
                childImageSharp {
                  resize(height: 300) {
                    src
                  }
                  original {
                    src
                  }
                }
              }
              id
              altText
            }
          }
        }
      }
    }
  `)

  function SampleNextArrow(props) {
    const { className, style, onClick } = props;
    return (
      <button 
        className = {className}
        style={{ ...style, display: "block", background: "transparent", right: "130px"}}
        onClick={onClick}
      >
      <NextButton />
      </button>
  
    );
  }
  
  function SamplePrevArrow(props) {
    const { className, style, onClick } = props;
    return (
      <button 
        className = {className}
        style={{ ...style, display: "block", background: "transparent", left: "50px", zIndex: 2}}
        onClick={onClick}
      >
      <PrevButton />
      </button>
  
    );
  }

    const settings = {
        dots: false,
        infinite: true,
        speed: 1000,
        slidesToShow: 5,
        slidesToScroll: 1,
        className: "slider variable-width",
        variableWidth: true,
        nextArrow: <SampleNextArrow />,
        prevArrow: <SamplePrevArrow />,
        responsive: [
            {
              breakpoint: 1440,
              settings: {
                slidesToShow: 3,
                slidesToScroll: 1,
              }
            },
            {
              breakpoint: 1024,
              settings: {
                slidesToShow: 2,
                slidesToScroll: 1,
                className: "center",
                centerMode: true,
                variableWidth: false,
              }
            },
            {
              breakpoint: 768,
              settings: {
                slidesToShow: 1,
                slidesToScroll: 1,
                // className: "center",
                variableWidth: false,
                centerMode: true,
              }
            },
            {
              breakpoint: 425,
              settings: {
                slidesToShow: 1,
                slidesToScroll: 1,
                // className: "center",
                variableWidth: false,
                // centerMode: true,
              }
            }
        ]
    };


    const images = data.allWpPage.nodes[0]
    const ImgGallery = images.postgallery.photos.map(({localFile, id,  altText}, index) => (
      <button onClick={() => openLightboxOnSlide(index + 1)} onKeyDown={() => openLightboxOnSlide(index + 1)}>
        <img src = {localFile.childImageSharp.resize.src} className="postgallery-img" key={id} alt={altText} />
      </button>
    ))
    const Sources = images.postgallery.photos.map(({localFile, id}) => (
      <img src = {localFile.childImageSharp.original.src} key={id} alt="" />
    ))
    

    function openLightboxOnSlide(number) {
        setLightboxController({
        toggler: !lightboxController.toggler,
        slide: number
        });
        }
    
    return(
        <>
            <Lightbox
                toggler={lightboxController.toggler}
                slide={lightboxController.slide}
                sources={Sources}
            />
            <Slider {...settings}>
                {ImgGallery}
            </Slider>
      </>
    )

}

KkbSlider.propTypes = {
  index: PropTypes.object.isRequired,
  openLightboxOnSlide: () => {},
  setLightboxController: () => {},
};


export default KkbSlider


