import React from 'react';
import ReactCompareImage from 'react-compare-image';

// IMAGES IMPORT 
import RealKkbImg from '../../../../images/equipment/real_kkb.jpg';
import threeDKkb from '../../../../images/equipment/3d_kkb.jpg';

export default function CompairKkbImgs() {
    return (
        <div style={{ maxWidth: '860px',  }}>
                <ReactCompareImage leftImage={ threeDKkb } rightImage={ RealKkbImg } />
        </div>
    )
}