import React from 'react';

export default function SliderPrevButton() {
    return (
      <svg viewBox="0 0 200 200" id="expbutton-prev">
        <g>
            <circle 
                class="circle"
                cx="100" 
                cy="100" 
                r="100" 
                fill="#ffffff" 
            />
            <polyline 
                class="polyline expertize-arrow-left"
                points="130,50, 70,100, 130,150"
            />
        </g>
      </svg>
     
    );
  }