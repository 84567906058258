import React from "react";
import {Modal} from 'react-bootstrap'
import ModalForm from "./ModalForm"

import CloseButton from "../../../Elements/SvgElements/OtherSvg/close.svg"


export default function ModalWindow (props) {

    
    return (
      <Modal
        {...props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        // centered
      >
          
        <Modal.Body>
            <div role="button" tabindex="-1" aria-label="Close-modal-button" className="close-btn" onClick={props.onHide} onKeyDown={props.onHide} >
              <img src={CloseButton} alt="" />
            </div>
            <div className="modal-header-refeng">
               <h2>ОТПРАВИТЬ ЗАПРОС</h2>
            </div>
            
          <ModalForm {...props} />
          
        </Modal.Body>
      </Modal>
    );
  }

