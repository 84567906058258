import React from 'react';

const ModelTable = () => {

    return(
        <>
        <h3 style={{textAlign: 'center'}}>Таблица характеристик компрессорно-конденсаторных агрегатов производства "Рефинжиниринг"</h3>
        <h4 style={{textAlign: 'center'}}>Подключение к оборотной воде +7/+12С</h4>
        <div class="table-wrap">
        <table border="1" style={{minWidth: '100%'}}>
            <tr>
                <th style={{width: '10%', textAlign: 'center'}} rowspan="2">Модель</th>
                <th style={{width: '30%', textAlign: 'center'}} colspan="2">Температура кипения -2С</th>
                <th style={{width: '30%', textAlign: 'center'}} colspan="2">Температура кипения -10С</th>
                <th style={{width: '30%', textAlign: 'center'}} colspan="2">Температура кипения -28С</th>
            </tr>
            <tr style={{textAlign: 'center'}}>
                <th>Q(КВт)</th>
                <th>N(КВт)</th>
                <th>Q(КВт)</th>
                <th>N(КВт)</th>
                <th>Q(КВт)</th>
                <th>N(КВт)</th>
            </tr>
            <tr style={{textAlign: 'center'}}>
            <td><strong>CCW-29</strong></td>
            <td>7,4</td>
            <td>1,0</td>
            <td>5,3</td>
            <td>1,0</td>
            <td>2,4</td>
            <td>1,0</td>
            </tr>
            <tr style={{textAlign: 'center'}}>
            <td><strong>CCW-32</strong></td>
            <td>8,0</td>
            <td>1,0</td>
            <td>5,8</td>
            <td>1,0</td>
            <td>2,6</td>
            <td>1,0</td>
            </tr>
            <tr style={{textAlign: 'center'}}>
            <td><strong>CCW-34</strong></td>
            <td>8,9</td>
            <td>1,2</td>
            <td>6,4</td>
            <td>1,2</td>
            <td>2,8</td>
            <td>1,1</td>
            </tr>
            <tr style={{textAlign: 'center'}}>
            <td><strong>CCW-36</strong></td>
            <td>9,3</td>
            <td>1,2</td>
            <td>6,7</td>
            <td>1,2</td>
            <td>2,9</td>
            <td>1,2</td>
            </tr>
            <tr style={{textAlign: 'center'}}>
            <td><strong>CCW-38</strong></td>
            <td>9,9</td>
            <td>1,3</td>
            <td>7,1</td>
            <td>1,3</td>
            <td>3,1</td>
            <td>1,3</td>
            </tr>
            <tr style={{textAlign: 'center'}}>
            <td><strong>CCW-40</strong></td>
            <td>10,7</td>
            <td>1,5</td>
            <td>7,7</td>
            <td>1,4</td>
            <td>3,4</td>
            <td>1,4</td>
            </tr>
            <tr style={{textAlign: 'center'}}>
            <td><strong>CCW-41</strong></td>
            <td>11,1</td>
            <td>1,4</td>
            <td>7,9</td>
            <td>1,5</td>
            <td>3,7</td>
            <td>1,5</td>
            </tr>
            <tr style={{textAlign: 'center'}}>
            <td><strong>CCW-44</strong></td>
            <td>12,2</td>
            <td>1,4</td>
            <td>8,7</td>
            <td>1,5</td>
            <td>4,0</td>
            <td>1,5</td>
            </tr>
            <tr style={{textAlign: 'center'}}>
            <td><strong>CCW-47</strong></td>
            <td>12,1</td>
            <td>1,6</td>
            <td>8,7</td>
            <td>1,6</td>
            <td>3,8</td>
            <td>1,5</td>
            </tr>
            <tr style={{textAlign: 'center'}}>
            <td><strong>CCW-48</strong></td>
            <td>12,5</td>
            <td>1,6</td>
            <td>9,0</td>
            <td>1,6</td>
            <td>4,0</td>
            <td>1,6</td>
            </tr>
            <tr style={{textAlign: 'center'}}>
            <td><strong>CCW-49</strong></td>
            <td>13,4</td>
            <td>1,6</td>
            <td>9,6</td>
            <td>1,7</td>
            <td>4,5</td>
            <td>1,7</td>
            </tr>
            <tr style={{textAlign: 'center'}}>
            <td><strong>CCW-51</strong></td>
            <td>14,3</td>
            <td>1,7</td>
            <td>10,2</td>
            <td>1,8</td>
            <td>4,8</td>
            <td>1,8</td>
            </tr>
            <tr style={{textAlign: 'center'}}>
            <td><strong>CCW-54</strong></td>
            <td>15,0</td>
            <td>1,8</td>
            <td>10,7</td>
            <td>1,9</td>
            <td>5,0</td>
            <td>2,0</td>
            </tr>
            <tr style={{textAlign: 'center'}}>
            <td><strong>CCW-56</strong></td>
            <td>15,5</td>
            <td>1,8</td>
            <td>11,0</td>
            <td>1,9</td>
            <td>5,2</td>
            <td>2,0</td>
            </tr>
            <tr style={{textAlign: 'center'}}>
            <td><strong>CCW-90</strong></td>
            <td>23,1</td>
            <td>2,8</td>
            <td>16,9</td>
            <td>3,2</td>
            <td>7,5</td>
            <td>3,1</td>
            </tr>
            <tr style={{textAlign: 'center'}}>
            <td><strong>CCW-105</strong></td>
            <td>27,6</td>
            <td>3,3</td>
            <td>20,5</td>
            <td>3,7</td>
            <td>9,5</td>
            <td>3,7</td>
            </tr>
            <tr style={{textAlign: 'center'}}>
            <td><strong>CCW-120</strong></td>
            <td>31,7</td>
            <td>3,7</td>
            <td>23,4</td>
            <td>4,1</td>
            <td>10,7</td>
            <td>4,1</td>
            </tr>
            <tr style={{textAlign: 'center'}}>
            <td><strong>CCW-140</strong></td>
            <td>35,9</td>
            <td>4,3</td>
            <td>26,6</td>
            <td>4,7</td>
            <td>12,5</td>
            <td>4,6</td>
            </tr>
            <tr style={{textAlign: 'center'}}>
            <td><strong>CCW-161</strong></td>
            <td>40,9</td>
            <td>4,8</td>
            <td>30,3</td>
            <td>5,2</td>
            <td>14,1</td>
            <td>5,2</td>
            </tr>
            <tr style={{textAlign: 'center'}}>
            <td><strong>CCW-184</strong></td>
            <td>46,3</td>
            <td>5,7</td>
            <td>34,4</td>
            <td>6,1</td>
            <td>16,3</td>
            <td>6,0</td>
            </tr>
            <tr style={{textAlign: 'center'}}>
            <td><strong>CCW-240</strong></td>
            <td>62,1</td>
            <td>7,6</td>
            <td>46,2</td>
            <td>8,2</td>
            <td>21,9</td>
            <td>8,1</td>
            </tr>
            <tr style={{textAlign: 'center'}}>
            <td><strong>CCW-295</strong></td>
            <td>76,0</td>
            <td>9,3</td>
            <td>56,5</td>
            <td>9,8</td>
            <td>26,8</td>
            <td>9,7</td>
            </tr>
            <tr style={{textAlign: 'center'}}>
            <td><strong>CCW-381</strong></td>
            <td>95,5</td>
            <td>11,7</td>
            <td>70,7</td>
            <td>12,5</td>
            <td>33,7</td>
            <td>12,6</td>
            </tr>
            <tr style={{textAlign: 'center'}}>
                <td><strong>CCW-485</strong></td>
                <td>122,4</td>
                <td>14,4</td>
                <td>91,4</td>
                <td>15,6</td>
                <td>45,0</td>
                <td>15,3</td>
            </tr>
            <tr style={{textAlign: 'center'}}>
                <td><strong>CCW-600</strong></td>
                <td>150</td>
                <td>18,0</td>
                <td>111,4</td>
                <td>19,4</td>
                <td>53,8</td>
                <td>19,3</td>
            </tr>
        </table>
        </div>
        </>
    )
}

export default ModelTable;