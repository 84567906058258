import React from "react"
import SliderNextButton from './SliderNextButton'


const NextButton = () => (
<>
    <SliderNextButton />
</>
)

export default NextButton